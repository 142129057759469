import styled from 'styled-components';

import { SIZES } from '@style/sizes';

export const IntermisionText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IntermisionTitle = styled.h2`
  color: ${(props) => props.theme.palette.primary};
  line-height: 28px;
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
export const SeparationDiv = styled.div`
  background-color: ${(props) => props.theme.palette.quinternary};
  height: 1px;
  width: 100%;
`;

export const IntermisionButton = styled.button`
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.common.green};
  color: #122c48;
  width: 190px;
  border-radius: 10px;
  height: 40px;
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: none;
  &:hover {
    background-color: ${(props) => props.theme.palette.sexternary};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const IntermisionSubtitle = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  width: 100%;
`;

export const BriefIntermision = styled.div`
  @media (max-width: ${SIZES.TABLET_800}px) {
    flex-direction: column;
    height: 100%;
    padding: 30px 0;
  }
  display: flex;
  justify-content: center;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  padding: 30px;
`;

export const BodyText = styled.p`
  line-height: 28px;
`;

export const ContentSubscribe = styled.div`
  width: 100%;
  margin: 20px 0;
`;
