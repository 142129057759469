import styled from 'styled-components';

export const BodyClarificationsWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const ClarificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.quinternary};
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
`;

export const BodyTitle = styled.h2`
  font-size: 24px;
  color: ${(props) => props.theme.palette.primary};
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  line-height: 26px;
  padding-bottom: 20px;
`;

export const ClarificationsBody = styled.div`
  padding-bottom: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.secondary};
`;

export const BodyText = styled.p`
  line-height: 26px;
  font-size: 18px;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
`;
