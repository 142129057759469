import styled from 'styled-components';

import { SIZES } from '@style/sizes';

export const TopClarificationsWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    width: 100%;
    margin: 0 0 20px;
    box-sizing: border-box;
  }
  background-color: ${(props) => props.theme.palette.quinternary};
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 30px;
  width: 40%;
  float: right;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
`;

export const BulletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BulletTitle = styled.h2`
  line-height: 28px;
  font-size: 24px;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  color: ${(props) => props.theme.palette.primary};
`;

export const Bullet = styled.div`
  background-color: ${(props) => props.theme.palette.common.green};
  height: 8px;
  width: 1px;
  padding: 0 4px;
  border-radius: 50px;
  margin-right: 5px;
  align-self: baseline;
  position: relative;
  top: 10px;
`;

export const BulletContent = styled.div`
  width: 100%;
  display: flex;
  font-size: 16px;
  color: ${(props) => props.theme.palette.secondary};
  flex-direction: row;
  align-items: center;
`;
