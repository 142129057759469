import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { IBlogData } from 'types/blog';
import { IInsight } from 'types/insight';

import Blog from '@components/BlogTemplate/components/Blog';
import Insights from '@components/Homepage/InsightsSlider';
import Layout from '@components/Layout';
import { SectionContainer } from '@components/Layout/Layout.styles';
import UserMessage from '@components/Layout/UserMessage';
import Modal from '@components/Modal';
import SEO from '@components/SEO';

import { PATHS } from '@global/urls';
import { post } from '@helpers/api';
import { UserMessageType } from '@helpers/enum/UserMessageTypes';
import { getImageData, ParseImages } from '@helpers/imageHelper';
import { hasWindow, trackEventAnalytics } from '@helpers/window';

import SubscribeModal from './components/Blog/SubscribeModal';

const BlogPage = (props: IBlogData) => {
  const { data, pageContext } = props;
  const images = ParseImages(data);
  const { t } = useTranslation();
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [userMessageType, setUserMessageType] = useState(UserMessageType.SUCCESS);
  const currentInsight: IInsight = {
    title: pageContext.blogData.Title,
    subtitle: pageContext.blogData.SubTitle,
    image: pageContext.blogData.CoverImage.data.attributes.url,
    author:
      pageContext.blogData.Author === 'Taylor_Gray_Ph_D'
        ? t('INSIGHTS:TAYLOR_AUTHOR_NAME')
        : t('INSIGHTS:KAI_AUTHOR_NAME'),
    readtime: pageContext.blogData.readTime,
    publishDate: pageContext.blogData.publishedAt,
    path: pageContext.blogData.path,
    summary: pageContext.blogData.Summary ? pageContext.blogData.Summary : undefined,
  };

  const blogs = data.cms.blogs.data;
  const insights: IInsight[] = [];
  blogs.forEach((bl: any) => {
    if (bl.attributes.Title !== undefined) {
      insights.push({
        title: bl.attributes.Title,
        image: bl.attributes.CoverImage.data.attributes.url,
        path: bl.attributes.path,
      });
    }
  });

  const onSubmit = async (userEmail: string) => {
    try {
      setLoading(true);
      await post('users/addToList', { email: userEmail });
      setLoading(false);
      setOpenSubscriptionModal(false);
      setUserMessageType(UserMessageType.SUCCESS);
      setUserMessage('Thanks for subscribing!');
    } catch (error) {
      setLoading(false);
      setOpenSubscriptionModal(false);
      setUserMessageType(UserMessageType.FAIL);
      setUserMessage('There was a problem subscribing you. Please try again.');
    }
  };

  const handleOpenModal = () => {
    trackEventAnalytics(!openSubscriptionModal ? 'Open Subscribe Modal' : 'Close Subscribe Modal', {
      route: PATHS.BLOG + pageContext.blogData.path,
    });
    setOpenSubscriptionModal(!openSubscriptionModal);
  };

  const handleCopyLink = () => {
    trackEventAnalytics('Click button copy blog link', {
      blogPath: currentInsight.path,
      route: PATHS.BLOG + pageContext.blogData.path,
    });
    setUserMessageType(UserMessageType.SUCCESS);
    setUserMessage('Link copied!');
  };

  useEffect(() => {
    if (userMessage !== '') {
      if (hasWindow()) {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        setUserMessage('');
      }, 3000);
    }
  }, [userMessage]);

  return (
    <Layout
      footerLogo={getImageData(images, 'footer')}
      currentRoute={PATHS.BLOG + currentInsight.path}
    >
      <SEO
        metaDescription={pageContext.blogData.SEO!.MetaDescription}
        metaTitle={pageContext.blogData.SEO!.MetaTitle}
        ogImage={currentInsight.image}
        twImage={currentInsight.image}
        path={currentInsight.path}
      />
      {userMessage !== '' && <UserMessage message={userMessage} messageType={userMessageType} />}
      <Modal open={openSubscriptionModal} width={500} onClose={handleOpenModal}>
        <SubscribeModal loading={loading} onSubmit={onSubmit} />
      </Modal>
      <Blog
        insight={currentInsight}
        blogBody={pageContext.blogData.Body}
        handleSubscribe={handleOpenModal}
        handleCopyLink={handleCopyLink}
      />
      <SectionContainer>
        <Insights
          insights={insights}
          isInsightPage={true}
          titleCopy={t('INSIGHTS:RECENT_POSTS_TITLE')}
          handleTrackButton={() => {}}
        />
      </SectionContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($language: String!) {
    cms {
      blogs(pagination: { limit: 5 }, sort: "publishedAt:desc") {
        data {
          id
          attributes {
            Title
            readTime
            publishedAt
            Author
            Body {
              ... on cms_ComponentBlogBodyText2 {
                id
                RichText
              }
              ... on cms_ComponentBlogClarification {
                id
                Clarification
              }
              ... on cms_ComponentBlogImage {
                id
                Image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on cms_ComponentBlogSubscribe {
                id
                Subscribe
              }
            }
            CoverImage {
              data {
                attributes {
                  alternativeText
                  url
                }
                id
              }
            }
            Summary {
              Title
              Bullet {
                BulletText
              }
            }
            SubTitle
            path
          }
        }
      }
    }
    allFile(
      filter: {
        relativeDirectory: {
          in: ["components/Layout/Topbar/assets", "components/Layout/Footer/assets"]
        }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: { in: ["topbar", "footer", "demoModal", "insights", "subscribeModal"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BlogPage;
