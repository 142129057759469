import React from 'react';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';

import { DefaultMarkdown } from './styles';

const GenericMarkdown = (props: any) => {
  const { children, ...rest } = props;

  return (
    <DefaultMarkdown
      remarkPlugins={[gfm]}
      {...rest}
      children={children}
      rehypePlugins={[rehypeRaw]}
    />
  );
};

export default GenericMarkdown;
