import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { validateEmail } from 'validators/email';

import TextButton from '@components/TextButton';
import TextInput from '@components/TextInput';

import { PATHS } from '@global/urls';

import {
  SubscribeWrapper,
  Title,
  WrapperTitle,
  InputWrapper,
  ButtonDiv,
  Info,
  InfoText,
  InfoLink,
  InputsWrapper,
  Mail,
} from './styles';
import { ISubscribeModalProps } from './types';

const SubscribeModal = (props: ISubscribeModalProps) => {
  const { t } = useTranslation();
  const { loading, onSubmit } = props;
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (value: string) => {
    setUserEmail(value.toLowerCase().replace(/\s+/g, ''));
  };

  const handleSubmit = () => {
    if (!validateEmail(userEmail)) {
      setEmailError('Incorrect email format');
    } else {
      onSubmit(userEmail);
    }
  };

  return (
    <SubscribeWrapper>
      <WrapperTitle>
        <Title> {t('SUBSCRIBE_MODAL:TITLE')}</Title>
      </WrapperTitle>
      <InputsWrapper>
        <InputWrapper>
          <TextInput
            isDisabled={false}
            error={emailError}
            id={t('SUBSCRIBE_MODAL:EMAIL').toLowerCase()}
            name={t('SUBSCRIBE_MODAL:EMAIL')}
            placeholder={t('SUBSCRIBE_MODAL:EMAIL')}
            value={userEmail}
            onChange={handleEmailChange}
            adornment={<Mail />}
          />
        </InputWrapper>
      </InputsWrapper>
      <ButtonDiv isDisabled={userEmail === ''}>
        <TextButton
          isDisabled={userEmail === ''}
          isLoading={loading}
          text={t('SUBSCRIBE_MODAL:SUBMIT')}
          onSubmit={handleSubmit}
        />
      </ButtonDiv>
      <Info>
        <InfoText>{t('DEMO_MODAL:INFO')}</InfoText>
        <InfoLink href={PATHS.PRIVACY} target="_blank">
          {t('DEMO_MODAL:INFO_LINK')}
        </InfoLink>
      </Info>
    </SubscribeWrapper>
  );
};

export default SubscribeModal;
