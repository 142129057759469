import styled from 'styled-components';

import { CardMedia } from '@mui/material';
import { SIZES } from '@style/sizes';

export const BodyClarificationsWrapper = styled.h2`
  width: 100%;
  padding-bottom: 60px;
`;

export const BodyElements = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding-bottom: 80px;
  }
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

export const ClarificationsBody = styled.div`
  padding-bottom: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.secondary};
`;

export const ClarificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.quinternary};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const MarkdownWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  flex: 10;
`;

export const BodyTitle = styled.h2`
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary};
  line-height: 28px;
`;

export const BodyText = styled.div`
  line-height: 26px;
  font-size: 18px;
  flex-direction: row-reverse;
  display: flex;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  color: ${(props) => props.theme.palette.common.grey};
`;

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 0;
  color: ${(props) => props.theme.palette.secondary};
`;

export const SocialIcon = styled.img`
  @media (max-width: ${SIZES.TABLET_992}px) {
    width: 40px;
    height: 40px;
  }
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const SocialLink = styled.a``;

export const SocialNetworksWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    justify-content: center;
    background-color: ${(props) => props.theme.palette.quinternary};
    display: flex;
    box-shadow: ${(props) => props.theme.boxShadow.module};
    top: 91%;
  }
  position: sticky;
  top: 10px;
  align-items: center;
  justify-content: left;
  height: 100%;
  z-index: 1;
  display: inline-block;
`;

export const SocialWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    justify-content: center;
  }
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: left;
`;

export const Body = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    flex-direction: column;
  }
  width: 100%;
  padding: 10px 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const BodyImage = styled.img`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.tertiary};
`;

export const ImageWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding: 50px 0;
  }
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SeparationDiv = styled.div`
  background-color: ${(props) => props.theme.palette.quinternary};
  height: 1px;
  width: 100%;
`;

export const ClarificationImage = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
`;
