import React from 'react';

import { hasWindow } from '@helpers/window';

import { parseBodyComponent } from './BlogBody';
import linkedin from './assets/linkedin.svg';
import sn from './assets/sn.svg';
import twitter from './assets/twitter.svg';
import {
  BodyElements,
  SocialNetworksWrapper,
  SocialWrapper,
  Body,
  SocialIcon,
  SocialLink,
} from './styles';
import { IBlogBodyProps } from './types';

const BlogBody = (props: IBlogBodyProps) => {
  const { body, summary, blogPath, handleSubscribe, handleCopyLink } = props;

  const handleCopyPath = () => {
    if (hasWindow()) {
      navigator.clipboard.writeText(window.location.host + blogPath);
      handleCopyLink();
    }
  };

  return (
    <Body>
      <SocialNetworksWrapper>
        <SocialWrapper>
          <SocialLink href="https://twitter.com/esgmotive">
            <SocialIcon src={twitter} />
          </SocialLink>
        </SocialWrapper>
        <SocialWrapper>
          <SocialLink href="https://www.linkedin.com/company/esgmotive/">
            <SocialIcon src={linkedin} />
          </SocialLink>
        </SocialWrapper>
        <SocialWrapper>
          <SocialIcon src={sn} onClick={handleCopyPath} />
        </SocialWrapper>
      </SocialNetworksWrapper>
      <BodyElements>
        {body.map((component: any, index: number) => {
          if (!component) {
            return;
          }
          if (index === 0 && summary) {
            return parseBodyComponent(component, handleSubscribe, summary);
          }
          return parseBodyComponent(component, handleSubscribe);
        })}
      </BodyElements>
    </Body>
  );
};

export default BlogBody;
