import styled from 'styled-components';

import MailIcon from '@mui/icons-material/Mail';
import { SIZES } from '@style/sizes';

export interface IDisabledProps {
  isDisabled: boolean;
}

export const SubscribeWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    margin-top: 10px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: none;
`;

export const Title = styled.h3`
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
`;

export const WrapperTitle = styled.div`
  padding-top: 15px;
`;

export const InputWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    width: 100%;
  }
  margin: 15px 0;
  width: 390px;
`;

export const InputsWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    width: 100%;
    margin: 10px 0;
  }
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 10px 35px;
`;

export const ButtonDiv = styled.div<IDisabledProps>`
  background-color: ${(props) => (props.isDisabled ? '#122C48' : props.theme.palette.common.green)};
  color: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  width: 234px;
  height: 50px;
  margin-top: 10px;
`;

export const Info = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    flex-direction: column;
    margin-top: 20px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const InfoText = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    margin-bottom: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamilies.europa.light};
  font-size: 12px;
  line-height: 18px;
  margin-right: 1px;
  text-align: center;
  color: ${(props) => props.theme.palette.common.white};
`;

export const InfoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  font-family: Europa-Light;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.palette.quaternary};
`;

export const Mail = styled(MailIcon)`
  color: ${(props) => props.theme.palette.secondary};
`;
