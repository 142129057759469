import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import GenericMarkdown from '@components/BlogTemplate/components/Blog/BlogBody/components/GenericMarkdown';

import {
  IntermisionText,
  IntermisionTitle,
  WrapperButton,
  IntermisionButton,
  IntermisionSubtitle,
  BriefIntermision,
  BodyText,
  SeparationDiv,
  ContentSubscribe,
} from './styles';
import { IBodyIntermisionProps } from './types';

const BodyIntermision = (props: IBodyIntermisionProps) => {
  const { t } = useTranslation();
  const { title, text, handleClick } = props;

  return (
    <ContentSubscribe>
      <SeparationDiv />
      <BriefIntermision>
        <IntermisionText>
          <IntermisionTitle>{title}</IntermisionTitle>
          <IntermisionSubtitle>
            <BodyText>
              <GenericMarkdown>{text}</GenericMarkdown>
            </BodyText>
          </IntermisionSubtitle>
        </IntermisionText>
        <WrapperButton>
          <IntermisionButton onClick={handleClick}>SUBSCRIBE</IntermisionButton>
        </WrapperButton>
      </BriefIntermision>
      <SeparationDiv />
    </ContentSubscribe>
  );
};
export default BodyIntermision;
