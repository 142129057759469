import React from 'react';

import GenericMarkdown from '../GenericMarkdown';
import {
  BodyClarificationsWrapper,
  BodyText,
  BodyTitle,
  ClarificationsBody,
  ClarificationsWrapper,
} from './styles';
import { IBodyClarificationProps } from './types';

const BodyClarification = (props: IBodyClarificationProps) => {
  const { title, clarification } = props;
  return (
    <BodyClarificationsWrapper>
      <ClarificationsWrapper>
        <BodyTitle>{title}</BodyTitle>
        <ClarificationsBody>
          <BodyText>
            <GenericMarkdown>{clarification}</GenericMarkdown>
          </BodyText>
        </ClarificationsBody>
      </ClarificationsWrapper>
    </BodyClarificationsWrapper>
  );
};
export default BodyClarification;
