import React from 'react';

import GenericMarkdown from '../GenericMarkdown';
import {
  Bullet,
  BulletTitle,
  BulletWrapper,
  TextWrapper,
  TopClarificationsWrapper,
  BulletContent,
} from './styles';
import { ISummaryProps } from './types';

const Summary = (props: ISummaryProps) => {
  const { summary } = props;

  return (
    <TopClarificationsWrapper>
      <TextWrapper>
        <BulletWrapper>
          <BulletTitle>{summary.Title}</BulletTitle>
          {summary.Bullet.map((element) => (
            <BulletContent>
              <Bullet />
              <GenericMarkdown>{element.BulletText}</GenericMarkdown>
            </BulletContent>
          ))}
        </BulletWrapper>
      </TextWrapper>
    </TopClarificationsWrapper>
  );
};

export default Summary;
