import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

export const DefaultMarkdown = styled(ReactMarkdown)`
  white-space: pre-wrap;
  li {
    display: flex;
  }
  li::before {
    display: inline-flex;
    margin-right: 10px;
    font-size: 30px;
    content: '•';
    color: ${(props) => props.theme.palette.common.green};
  }

  ol {
    list-style: decimal inside;
  }

  strong {
    display: contents;
  }

  h2 {
    line-height: 26px;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
    color: ${(props) => props.theme.palette.primary};
  }
  h3 {
    line-height: 26px;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
    color: ${(props) => props.theme.palette.primary};
  }
  h4 {
    line-height: 26px;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
    color: ${(props) => props.theme.palette.primary};
  }
  a {
    color: ${(props) => props.theme.palette.common.grey};
  }
`;
