import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import BlogBody from '@components/BlogTemplate/components/Blog/BlogBody';
import { SectionContainer } from '@components/Layout/Layout.styles';

import { handleThumbnail } from '@helpers/imageHelper';
import { SIZES } from '@style/sizes';

import {
  AuthorHeader,
  AuthorImage,
  BlogWrapper,
  CardMediaWrapper,
  CardWrapper,
  HeaderTextWrapper,
  ImageWrapper,
  InsightAuthor,
  InsightDate,
  InsightDiv,
  InsightHeader,
  InsightInfo,
  InsightPage,
  InsightReadTime,
  InsightsWrapper,
  MetaHeader,
  SeparationDiv,
  Separator,
  SubTitle,
  SubTitleWrapper,
  Title,
  TitleWrapper,
} from './styles';
import { IBlogProps } from './types';

const Blog = (props: IBlogProps) => {
  const { insight, blogBody, handleSubscribe, handleCopyLink } = props;
  const { t } = useTranslation();
  const publishDate = new Date(insight.publishDate!);

  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.MOBILE_575}px)`,
  });

  return (
    <InsightPage>
      <SectionContainer>
        <InsightsWrapper>
          <BlogWrapper>
            <HeaderTextWrapper>
              <TitleWrapper>
                <Title>{insight.title}</Title>
              </TitleWrapper>
              <SubTitleWrapper>
                <SubTitle>{insight.subtitle}</SubTitle>
              </SubTitleWrapper>
            </HeaderTextWrapper>
            <SeparationDiv />
            <InsightDiv>
              <CardWrapper>
                <InsightHeader>
                  {isMobile && <AuthorImage src={handleThumbnail(insight.author!)} />}
                  <MetaHeader>
                    <AuthorHeader>
                      {!isMobile && <AuthorImage src={handleThumbnail(insight.author!)} />}
                      <InsightAuthor>{insight.author}</InsightAuthor>
                    </AuthorHeader>
                    <InsightInfo>
                      <InsightReadTime>
                        {!isMobile && <Separator>{t('INSIGHTS:SEPARATOR')}</Separator>}
                        {publishDate.toLocaleString('en-US', { month: 'long', day: 'numeric' })}
                      </InsightReadTime>
                      <InsightDate>
                        <Separator>{t('INSIGHTS:SEPARATOR')}</Separator>
                        {insight.readtime!} {t('INSIGHTS:READ_TIME')}
                      </InsightDate>
                    </InsightInfo>
                  </MetaHeader>
                </InsightHeader>
                <ImageWrapper>
                  <CardMediaWrapper image={insight.image} />
                </ImageWrapper>
                <BlogBody
                  body={blogBody}
                  handleCopyLink={handleCopyLink}
                  summary={insight.summary!}
                  blogPath={'/blog/' + insight.path}
                  handleSubscribe={handleSubscribe}
                />
              </CardWrapper>
            </InsightDiv>
          </BlogWrapper>
        </InsightsWrapper>
      </SectionContainer>
    </InsightPage>
  );
};
export default Blog;
