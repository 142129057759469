import styled from 'styled-components';

import { CardMedia } from '@mui/material';
import { SIZES } from '@style/sizes';

export const InsightPage = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.quinternary};
`;

export const InsightsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const InsightImage = styled.img`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding: 20px 0 0 0;
  }
  width: 100%;
  height: 100%;
  padding: 20px 0;
`;

export const InsightTitle = styled.a`
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  color: ${(props) => props.theme.palette.primary};
  font-size: 30px;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  padding: 15px 0 15px 0;
  line-height: 30px;
`;

export const InsightLink = styled.a`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  color: ${(props) => props.theme.palette.septernary};
  font-size: 20px;
  line-height: 30px;
  text-align: left;
`;

export const Title = styled.h2`
  display: flex;
  color: ${(props) => props.theme.palette.primary};
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 42px;
  line-height: 55px;
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.regular};
  font-size: 20px;
  line-height: 30px;
`;

export const SubTitleWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding: 0;
  }
  width: 100%;
  display: flex;
  padding: 20px 20px 0;
  align-items: center;
  box-sizing: border-box;
  justify-content: left;
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding: 0;
  }
  display: flex;
  width: 94%;
  padding: 0 20px;
  box-sizing: border-box;
`;

export const InsightDiv = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding: 0;
  }
  padding: 20px 20px;
`;

export const CardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InsightHeader = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    width: 60%;
    justify-content: center;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  gap: 20px;
  justify-content: flex-start;
  align-self: flex-start;
`;

export const InsightAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  white-space: nowrap;
  color: ${(props) => props.theme.palette.common.grey};
`;

export const InsightAuthorName = styled.div`
  color: ${(props) => props.theme.palette.common.grey};
  white-space: nowrap;
`;

export const InsightDate = styled.div`
  color: ${(props) => props.theme.palette.common.grey};
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

export const Separator = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: center;
  color: ${(props) => props.theme.palette.quaternary};
`;

export const InsightReadTime = styled.div`
  display: flex;
  white-space: nowrap;
  color: ${(props) => props.theme.palette.common.grey};
`;

export const InsightInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

export const CardMediaWrapper = styled(CardMedia)`
  @media (max-width: ${SIZES.TABLET_992}px) {
    height: 350px;
  }
  @media (max-width: ${SIZES.TABLET_740}px) {
    height: 200px;
  }
  height: 500px;
  border: 1px solid ${(props) => props.theme.palette.tertiary};
  width: 100%;
  margin-right: 15px;
`;

export const SeparationDiv = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    margin-bottom: 20px;
  }
  @media (max-width: ${SIZES.TABLET_800}px) {
    width: 90%;
    display: flex;
    align-self: center;
  }
  background-color: ${(props) => props.theme.palette.tertiary};
  height: 1px;
  width: 100%;
`;

export const BlogWrapper = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    padding: 0;
  }
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  box-sizing: border-box;
`;

export const HeaderTextWrapper = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    width: 90%;
  }
  width: 100%;
  padding: 25px 0;
  text-align: left;
  align-self: center;
`;

export const AuthorImage = styled.img`
  border-radius: 50px;
  width: 40px;
  height: 40px;
`;

export const AuthorHeader = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    padding-left: 30px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    padding-left: 0;
  }
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const MetaHeader = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    flex-direction: column;
    display: block;
  }
  display: flex;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 16px;
  align-items: center;
`;
