import React from 'react';
import { ISummary } from 'types/insight';

import BodyIntermision from '@components/BlogTemplate/components/Blog/BlogBody/components/BodyIntermision';

import BodyClarification from './components/BodyClarification';
import Summary from './components/BodySummary';
import GenericMarkdown from './components/GenericMarkdown';
import {
  BodyText,
  BodyWrapper,
  BodyImage,
  ClarificationImage,
  ImageWrapper,
  MarkdownWrapper,
} from './styles';

export const parseBodyComponent = (
  component: any,
  handleSubscribe: () => void,
  summary?: ISummary,
) => {
  switch (component.__typename) {
    case 'cms_ComponentBlogSubscribe':
      return (
        <BodyIntermision
          title={component.Title}
          text={component.Subscribe}
          handleClick={handleSubscribe}
        />
      );
    case 'cms_ComponentBlogClarification':
      return <BodyClarification clarification={component.Clarification} title={component.Title} />;
    case 'cms_ComponentBlogImage':
      return (
        <ImageWrapper>
          <BodyImage src={component.Image.data.attributes.url} />
          <ClarificationImage>{component.ClarificationImage}</ClarificationImage>
        </ImageWrapper>
      );
    default:
      return (
        <BodyWrapper>
          <BodyText>
            <MarkdownWrapper>
              {summary && <Summary summary={summary} />}
              <GenericMarkdown>{component.RichText}</GenericMarkdown>
            </MarkdownWrapper>
          </BodyText>
        </BodyWrapper>
      );
  }
};
